<template>
  <div>
    <div class="table_box">
      <div class="down_box" v-for="(item, index) in wareList" :key="index">
        <span class="icon-box">
          <GIcon class="down-icon" :icon="typeMap[item.osType].icon" />
        </span>
        <p class="name">{{ typeMap[item.osType].text }} <span>最新版本: {{ item.latestVersion }}</span></p>
        <div class="global-flex" v-for="(tItem, tIndex) in item.content" :key="tIndex">
          <span class="global-flex-label">—</span>
          <span class="global-flex-value">{{ tItem }}</span>
        </div>
        <el-button class="down-btn" type="primary" @click="down(item)">
          <GIcon v-if="item.osType !== 'iOS'" :icon="typeMap[item.osType].downIcon"></GIcon>
          {{ typeMap[item.osType].downText }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import { Popover } from 'element-ui'

  export default {
    name: 'Software',
    components: { 'el-popover': Popover },
    data() {
      return {
        wareList: [],
        typeMap: {
          'Windows': { text: 'Windows', icon: 'icon-windows1', downIcon: 'icon-windows', downText: '下载' },
          'Mac': { text: 'Mac', icon: 'icon-mac-os', downIcon: 'icon-ios1', downText: '下载' },
          'iOS': { text: 'iPad', icon: 'icon-ipad1', downIcon: 'icon-iPad', downText: 'App Store搜索「画眉悦览」安装' },
        }
      }
    },
    created() {
      this.$axios.get('/athena/soft/list').then(res => {
        if (res.data.length) {
          res.data.forEach(v => {
            v.content = v.latestContext.split('[enter]')
          })
        }
        this.wareList = res.data
      })
    },
    methods: {
      down(item) {
        if (item.osType === 'iOS') return;
        window.open(item.latestUrl)
      }
    }
  }
</script>

<style scoped lang="scss">
  .table_box {
    width: 1000px;
    margin: 48px auto 0;
    padding-top: 48px;
    height: calc(100% - 180px);
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    .down_box {
      position: relative;
      width: 280px;
      height: 255px;
      margin-right: 20px;
      margin-bottom: 50px;
      padding: 12px;
      background-color: #fff;
      box-shadow: $cardShadow;
      cursor: pointer;
      border-radius: 6px;
      &:hover{
        .icon-box{
          transform: rotate(-25deg);
        }
      }
      .icon-box{
        margin: -50px auto 0;
        transition: all .3s;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: $cardShadow;
        border-radius: 50%;
        width: max-content;
        padding: 10px;
        .down-icon{
          display: block;
          font-size: 60px;
          color: #fff;
        }
      }
      .global-flex{
        font-size: 14px;
        color: $title2;
        align-items: baseline;
        font-weight: 600;
        margin-bottom: 12px;
        .global-flex-label{
          font-weight: normal;
          margin-right: 10px;
        }
      }
      .download_btn{
        img{
          margin-right: 4px;
        }
        color: #fff;
      }
      .down-btn{
        position: absolute;
        left: 50%;
        bottom: 12px;
        font-size: 13px;
        width: 80%;
        transform: translateX(-50%);
        margin: 0 auto;
        ::v-deep{
          .icon{
            margin-right: 6px;
          }
        }
      }
      &:hover {
        box-shadow: 0 0 18px rgba(0, 0, 0, 0.25);
      }
      .logo {
        display: block;
        margin: 0 auto;
      }
      .name {
        margin: 24px 0;
        text-align: center;
        color: $title1;
        font-size: 18px;
        font-weight: bold;
        &>span{
          display: inline-block;
          margin-left: 4px;
          color: $title1;
          font-weight: 500;
          font-size: 12px;
        }
      }
    }
  }
  .qr_img {
    display: block;
    width: 200px;
    height: 200px;
  }
</style>
